import { useLayoutEffect, useState } from 'preact/compat';
import useWatchlistBroker from '../../../hooks/use-watchlist-broker';
const emptyAssetSymbolsList = [];
export default function useWatchlistAssetSymbols() {
    const watchlistBroker = useWatchlistBroker();
    const [assetSymbols, setAssetSymbols] = useState(emptyAssetSymbolsList);
    useLayoutEffect(()=>watchlistBroker === null || watchlistBroker === void 0 ? void 0 : watchlistBroker.on(setAssetSymbols), [
        watchlistBroker
    ]);
    return {
        isLoading: watchlistBroker !== undefined && assetSymbols === emptyAssetSymbolsList,
        value: assetSymbols
    };
}
