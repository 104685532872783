import { useEffect, useMemo, useState } from 'preact/compat';
import isAssetTradable from '../services/asset/is-asset-tradable';
import useAssetsDictionary from './use-assets-dictionary';
import useQuotecast from './use-quotecast';
const emptySymbolsList = [];
export default function useMostTradedAssets() {
    const quotecast = useQuotecast();
    const [symbols, setSymbols] = useState(emptySymbolsList);
    const { value: assetsDictionary } = useAssetsDictionary();
    useEffect(()=>quotecast === null || quotecast === void 0 ? void 0 : quotecast.onMostTradedAssets(setSymbols), [
        quotecast
    ]);
    return useMemo(()=>{
        if (!assetsDictionary) {
            return [];
        }
        return symbols.map((symbol)=>assetsDictionary.get(symbol)).filter((asset)=>asset !== undefined && isAssetTradable(asset));
    }, [
        assetsDictionary,
        symbols
    ]);
}
