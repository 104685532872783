import { useLayoutEffect, useState } from 'preact/compat';
import useUpvoteBroker from './use-upvote-broker';
export default function useAssetUpvoteInfo(assetSymbol) {
    const upvoteBroker = useUpvoteBroker();
    const [upvoteInfo, setUpvoteInfo] = useState();
    useLayoutEffect(()=>{
        setUpvoteInfo(undefined);
        return upvoteBroker === null || upvoteBroker === void 0 ? void 0 : upvoteBroker.on((upvoteList)=>setUpvoteInfo(upvoteList[assetSymbol]));
    }, [
        upvoteBroker,
        assetSymbol
    ]);
    return upvoteInfo;
}
