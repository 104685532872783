import { useEffect, useState } from 'preact/compat';
import useQuotecast from './use-quotecast';
export default function useMarketData() {
    const quotecast = useQuotecast();
    const [marketData, setMarketData] = useState({
        relativeDifference: undefined
    });
    useEffect(()=>quotecast === null || quotecast === void 0 ? void 0 : quotecast.onMarketData(setMarketData), [
        quotecast
    ]);
    return marketData;
}
