import { useLayoutEffect, useState } from 'preact/compat';
import useAutoInvestBroker from './use-auto-invest-broker';
export default function useScheduledInvestments() {
    const autoInvestBroker = useAutoInvestBroker();
    const [scheduledInvestments, setScheduledInvestments] = useState();
    useLayoutEffect(()=>{
        return autoInvestBroker === null || autoInvestBroker === void 0 ? void 0 : autoInvestBroker.on(setScheduledInvestments);
    }, [
        autoInvestBroker
    ]);
    return {
        isLoading: autoInvestBroker !== undefined && !scheduledInvestments,
        value: scheduledInvestments
    };
}
