import createSortValuesComparator from '@finst/core/src/scripts/services/api/create-sort-values-comparator';
import serializeSortValues from '@finst/core/src/scripts/services/api/serialize-sort-values';
import isNonEmptyArray from '@finst/core/src/scripts/utils/collection/is-non-empty-array';
import { useLayoutEffect, useMemo, useState } from 'preact/compat';
import useBaseAsset from '../../../../hooks/use-base-asset';
import useQuotecast from '../../../../hooks/use-quotecast';
import useUpvoteBroker from '../../../../hooks/use-upvote-broker';
import useWatchlistAssetSymbols from '../../../watchlist/hooks/use-watchlist-asset-symbols';
export default function useSortedAssets(assets, sortValues) {
    const quotecast = useQuotecast();
    const upvoteBroker = useUpvoteBroker();
    const { symbol: baseAssetSymbol } = useBaseAsset();
    const [enforcedSortingCounter, setForceSortingCounter] = useState(0);
    const [upvoteList, setUpvoteList] = useState();
    const { value: watchlistAssetSymbols } = useWatchlistAssetSymbols();
    const sortValuesKey = useMemo(()=>serializeSortValues(sortValues), [
        sortValues
    ]);
    const quotecastSortFields = useMemo(()=>[
            ...sortValues.keys()
        ].filter((field)=>field !== 'name' && field !== 'isInWatchlist' && field !== 'totalUpvotes').sort(), [
        sortValues
    ]);
    const hasTotalUpvotesSortValue = sortValues.has('totalUpvotes');
    useLayoutEffect(()=>{
        if (hasTotalUpvotesSortValue && upvoteBroker) {
            // save upvote list only once
            return upvoteBroker.on((newUpvoteList)=>setUpvoteList((upvoteList)=>upvoteList || newUpvoteList));
        }
        // do not store the old list & free up memory
        setUpvoteList(undefined);
    }, [
        hasTotalUpvotesSortValue,
        upvoteBroker
    ]);
    useLayoutEffect(()=>{
        const pairs = assets.map((asset)=>`${asset.symbol}~${baseAssetSymbol}`);
        if (quotecast && isNonEmptyArray(pairs) && isNonEmptyArray(quotecastSortFields)) {
            return quotecast.onUpdate(pairs, quotecastSortFields, (update)=>{
                assets.forEach((asset)=>{
                    const data = update[`${asset.symbol}~${baseAssetSymbol}`];
                    if (data) {
                        // accumulate new values in `asset` as they will be used in sorting
                        Object.assign(asset, data);
                    }
                });
                setForceSortingCounter((id)=>id + 1);
            });
        }
    // eslint-disable-next-line @finst/react-hooks-exhaustive-deps
    }, [
        baseAssetSymbol,
        quotecast,
        assets,
        String(quotecastSortFields)
    ]);
    return useMemo(()=>{
        const compare = createSortValuesComparator(sortValues, (asset, sortColumn)=>{
            var _upvoteList_asset_symbol;
            return sortColumn === 'isInWatchlist' ? Number(watchlistAssetSymbols.includes(asset.symbol)) : sortColumn === 'totalUpvotes' ? upvoteList === null || upvoteList === void 0 ? void 0 : (_upvoteList_asset_symbol = upvoteList[asset.symbol]) === null || _upvoteList_asset_symbol === void 0 ? void 0 : _upvoteList_asset_symbol.totalUpvotes : asset[sortColumn];
        });
        return assets.slice(0).sort(compare);
    // eslint-disable-next-line @finst/react-hooks-exhaustive-deps
    }, [
        assets,
        watchlistAssetSymbols,
        upvoteList,
        sortValuesKey,
        enforcedSortingCounter
    ]);
}
